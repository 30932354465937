import React from 'react';
import { Modal } from '@mui/material';
import {ModalDialog, ModalClose, Card} from "@mui/joy";

interface ViewAllMessageProps {
    open: boolean;
    onClose: () => void;
    title: string;
    content: string;
}

export const ViewAllMessageModal: React.FC<ViewAllMessageProps> = ({ open, title, content, onClose }) => {
    return (
        <Modal open={open} onClose={onClose}>
            <ModalDialog
                color="neutral"
                layout="center"
                size="lg"
                style={{width: "50%", textAlign: "center"}}
                variant="outlined">
                <ModalClose variant={"outlined"} onClick={()=>onClose()} />
                <h1>{title}</h1>
                <Card sx={{maxHeight: '80vh', overflow: 'auto'}}>
                    <p>{content}</p>
                </Card>
            </ModalDialog>
        </Modal>
    );
};