import React from 'react';
import {Box, Container, Typography} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";

export const Footer = () => {
    return (
        <Box
            sx={{
                backgroundColor: "#f5f5f5",
                height: "10vh"}}
        >
            <Container maxWidth="lg">
                <br/>
                <Grid container direction="column" alignItems="center">
                    <Grid xs={12}>
                        <Typography color="black" variant="h5">
                                KSTMD
                        </Typography>
                    </Grid>
                    <Grid xs={12}>
                        <Typography color="textSecondary" variant="subtitle1">
                            {`${new Date().getFullYear()} | 김세현이 만듦`}
                        </Typography>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    );
};