import React from 'react';
import {BrowserRouter, Routes, Route, useLocation} from "react-router-dom";
import {ToastContainer} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

import './index.css';
import ResponsiveAppBar from "./components/AppBar";
import {Footer} from "./components/Footer";
import MainPage from './Page/MainPage';
import NotFoundPage from "./Page/NotFoundPage";
import DataViewPage from "./Page/DataViewPage";
import AdminDataViewPage from "./Page/AdminDataViewPage";
import SignInPage from "./Page/SignInPage";
import SignUpPage from "./Page/SignUpPage";
import SignOutPage from "./Page/SignOutPage";

// context
import { UserProvider } from './context/UserContext';

const AppContext = () => {
    const location = useLocation();
    const isLoginPage = location.pathname === '/signin' || location.pathname === '/signup';

    return (
        <div className="main-content">
            <ToastContainer/>
            <div className="min90">
                {!isLoginPage && <ResponsiveAppBar/>}
                <Routes>
                    {/* Main Page */}
                    <Route path="/" element={<MainPage/>}/>
                    <Route path="/data" element={<DataViewPage/>}/>

                    {/* User Page */}
                    <Route path="/signin" element={<SignInPage/>}/>
                    <Route path="/signup" element={<SignUpPage/>}/>
                    <Route path="/signout" element={<SignOutPage/>}/>

                    {/* Admin Page */}
                    <Route path="/admin/data" element={<AdminDataViewPage/>}/>
                    <Route path="*" element={<NotFoundPage/>}/>
                </Routes>
            </div>
            {!isLoginPage && <Footer/>}
        </div>
    )
}


export default function ServiceAppRouter() {
    return (
        <BrowserRouter>
            <UserProvider>
                <AppContext/>
            </UserProvider>
        </BrowserRouter>
    );
}
