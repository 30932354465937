import { useNavigate } from "react-router-dom";
import axios from 'axios';
import Cookies from 'js-cookie';
import { toast } from "react-toastify";

// Context
import { useUser } from "../context/UserContext";

// style
import { toastOption } from "./toastOptions";

const axiosInstance = axios.create({
    baseURL: 'https://kstmd.xyz/api',
    headers: {
        'Content-Type': 'application/json',
    },
});

axiosInstance.interceptors.request.use(
    (config) => {
        const token = Cookies.get('accessToken');
        if (token) {
            config.headers['Authorization'] = `Bearer ${token}`;
        }
        return config;
    },
    (error) => {
        if (error.response && error.response.status === 401) {
            const { setAccessToken } = useUser();
            const navigate = useNavigate();
            setAccessToken(null);
            toast.error('로그인이 필요합니다.', toastOption);
            navigate('/signin');
        }
        return Promise.reject(error);
    }
);

export default axiosInstance;