import React, { useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { Box, Container, Stack, Button } from "@mui/material";
import { Card, Textarea } from "@mui/joy";
import { toast } from "react-toastify";

// components
import {Loading} from "../components/Loading";

// context
import {useUser} from "../context/UserContext";

// style
import styles from '../style/App.module.css';

// utils
import axiosInstance from "../utils/axiosInstance";
import { toastOption } from "../utils/toastOptions";



interface APIData {
    result: string,
    isSpam: boolean
}

export default function MainPage(){
    const navigate = useNavigate();
    const {accessToken} = useUser();

    const [spamText, setSpamText] = React.useState<string>("");
    const [spamTextDisabled, setSpamTextDisabled] = React.useState<boolean>(false);
    const [loading, setLoading] = React.useState<boolean>(false);
    const [spamTestResult, setSpamTestResult] = React.useState<string>("검사전");

    const handleSpamText = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        setSpamText(e.target.value);
    }

    const checkSpamText = async () => {
        if(accessToken === null){
            toast.warning('로그인이 필요합니다.', toastOption);
            navigate("/signin");
            return;
        }

        if(spamText === ""){
            toast.warning('스팸으로 의심되는 문자를 입력해주세요.', toastOption);
            return;
        }

        setLoading(true);
        try {
            const response = await axiosInstance.post("/sms", {"text": spamText});
            if (response.status === 200) {
                const result: APIData = response.data as APIData;
                if(result.result === "success"){
                    if(result.isSpam) setSpamTestResult("스팸");
                    else setSpamTestResult("스팸이 아님");

                    // set View
                    setLoading(false);
                } else new Error("서버 값 오류");
            }
        } catch (e) {
            setLoading(false);
            toast.error('서버와 통신에 실패하였습니다.', toastOption);
            console.error(e);
            return;
        }
        return;
    }

    useEffect(() => {
        if(accessToken === null){
            setSpamText("로그인이 필요합니다.")
            setSpamTextDisabled(true);
        } else {
            setSpamTextDisabled(false);
            setSpamText("");
        }
    }, [accessToken]);

    return (
        <Stack spacing={0} style={{backgroundColor: "#7f7f7f"}}>
            <Loading open={loading} status={"검사중 입니다...."} subStatus={"최대 n분이 소요될 수 있습니다."}/>
            <Box className={styles.banner} style={{backgroundImage: `linear-gradient(rgba(0, 0, 0, .5), rgba(0, 0, 0, .5)`}}>
                <Box display="flex"
                     justifyContent="center"
                     alignItems="center"
                     minHeight="100%">
                    <Stack style={{alignItems: "center"}}>
                        <h1>KSTMD</h1>
                        <h2>Korean Spam Text Message Dataset</h2>
                    </Stack>
                </Box>
            </Box>
            <Box className={styles.section}>
                <Container>
                    <Card style={{height: '100%'}} variant="outlined">
                        <Stack style={{alignItems: "center"}}>
                            <h1 style={{marginBottom: 0}}>스팸으로 의심되는 문자를 입력해주세요.</h1>
                            <Textarea
                                minRows={2}
                                disabled={spamTextDisabled}
                                placeholder="문자 내용을 입력해주세요."
                                variant="outlined"
                                size="lg"
                                onChange={handleSpamText}
                                value={spamText}
                                style={{width: '100%', marginTop: 10}}/>
                            <h3>검사 결과 : {spamTestResult}</h3>
                            <Button
                                variant="contained"
                                onClick={checkSpamText}>
                                검사하기
                            </Button>
                        </Stack>
                    </Card>
                </Container>
            </Box>
        </Stack>
    )
}
