// src/context/UserContext.tsx
import React, { createContext, useState, useContext, useEffect } from 'react';
import Cookies from 'js-cookie';

interface UserContextType {
    accessToken: string | null;
    setAccessToken: (token: string | null) => void;
}

const UserContext = createContext<UserContextType | undefined>(undefined);

export const UserProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [accessToken, setAccessTokenState] = useState<string | null>(null);

    useEffect(() => {
        const savedToken = Cookies.get('accessToken');
        if (savedToken) setAccessTokenState(savedToken);
    }, []);

    const setAccessToken = (token: string | null) => {
        setAccessTokenState(token);
        if (token) {
            Cookies.set('accessToken', token, { expires: 1 / 24 });
        } else {
            Cookies.remove('accessToken');
        }
    };

    return (
        <UserContext.Provider value={{ accessToken, setAccessToken }}>
            {children}
        </UserContext.Provider>
    );
};

export const useUser = () => {
    const context = useContext(UserContext);
    if (!context) {
        throw new Error('useUser must be used within a UserProvider');
    }
    return context;
};