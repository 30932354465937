import React, { useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

// context
import { useUser } from "../context/UserContext";

// components
import { Loading } from "../components/Loading";

// style
import { toastOption } from "../utils/toastOptions";

// utils
import axiosInstance from "../utils/axiosInstance";

interface APIData {
    result: string
}

export default function SignOutPage() {
    const navigate = useNavigate();
    const { setAccessToken } = useUser();

    const handleSignOut = async () => {
        try {
            const response = await axiosInstance.post("/signout");
            if (response.status === 200) {
                const result: APIData = response.data as APIData;
                setAccessToken(null);
                if(result.result === "success"){
                    toast.success('로그아웃에 성공하였습니다.', toastOption);
                } else {
                    toast.warning('이미 로그아웃 상태입니다.', toastOption);
                }
                navigate("/");
            }
        } catch (e) {
            toast.error('서버와 통신에 실패하였습니다.', toastOption);
            console.error(e);
            navigate("/");
        }
        return;
    }

    useEffect(() => {
        handleSignOut();
    });

    return (
        <>
            <Loading open={true} status={"로그아웃 중입니다..."}/>
        </>
    );
}