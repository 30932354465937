import  React from 'react';
import { useNavigate } from "react-router-dom";
import { Box, Button, Container, Link, Stack, TextField } from "@mui/material";
import { Card, ModalClose } from "@mui/joy";
import { toast } from "react-toastify";

// components
import { Loading } from "../components/Loading";

// style
import { toastOption } from "../utils/toastOptions";

// utils
import axiosInstance from "../utils/axiosInstance";

interface APIData {
    result: string,
    username: string,
}


// TODO : 회원가입 로직 수정 (UI만 완성됨)
export default function SignUpPage() {
    const navigate = useNavigate();
    const [loading, setLoading] = React.useState<boolean>(false);
    const [userId, setUserId] = React.useState<string>("");
    const [userPw, setUserPw] = React.useState<string>("");

    const [userIdError, setUserIdError] = React.useState<boolean>(false);
    const [userPwError, setUserPwError] = React.useState<boolean>(false);

    const handleUserIdChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const regExp = /^[a-zA-Z0-9]*$/;
        const inputValue = e.target.value;

        setUserId(e.target.value);
        if(!regExp.test(inputValue) || inputValue.length > 20 || inputValue.length === 0){
            setUserIdError(true);
        } else {
            setUserIdError(false);
        }
    }
    const handleUserPwChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const inputValue = e.target.value;

        setUserPw(e.target.value);
        if(inputValue.length === 0){
            setUserPwError(true);
        } else {
            setUserPwError(false);
        }
    }


    const handleLogin = async () => {
        setLoading(true);

        if(userIdError || userPwError){
            setLoading(false);
            toast.error('입력값을 확인해주세요.', toastOption);
            return;
        }

        try {
            const response = await axiosInstance.post("/signup", {"username": userId, "password": userPw});
            if (response.status === 200) {
                console.log(response.data);
                const result: APIData = response.data as APIData;
                if(result.result === "success"){
                    navigate("/");
                    console.log("Login Success");
                } else {
                    console.log("Login Fail");
                }
                setLoading(false);
            }
        } catch (e) {
            setLoading(false);
            toast.error('서버와 통신에 실패하였습니다.', toastOption);
            console.error(e);
            return;
        }
        return;
    };

    return (
        <Container>
            <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                height="100vh">
                <Loading open={loading} status={"회원가입 중입니다..."}/>
                <Card variant="outlined" sx={{width: "45vh"}}>
                    <ModalClose variant="soft" onClick={() => window.history.back()}></ModalClose>
                    <Stack style={{alignItems: "center"}}>
                        <h1 style={{marginBottom: 0}}>KSTMD Register</h1>
                        <p>서비스 이용을 위해 회원가입을 진행합니다.</p>
                    </Stack>
                    <Stack spacing={2} style={{alignItems: "center"}}>
                        <TextField
                            label="User Name" variant="outlined"
                            value={userId} onChange={handleUserIdChange}
                            error={userIdError} helperText={userIdError ? "Username을 정확하게 입력해주세요." : ""}
                            fullWidth/>
                        <TextField
                            label="Eamil" variant="outlined"
                            value={userId} onChange={handleUserIdChange}
                            error={userIdError} helperText={userIdError ? "Username을 정확하게 입력해주세요." : ""}
                            fullWidth/>
                        <TextField
                            label="Password" variant="outlined" type="password"
                            value={userPw} onChange={handleUserPwChange}
                            error={userPwError} helperText={userPwError ? "Password를 정확하게 입력해주세요." : ""}
                            fullWidth/>
                        <TextField
                            label="Repeat Password" variant="outlined" type="password"
                            value={userPw} onChange={handleUserPwChange}
                            error={userPwError} helperText={userPwError ? "Password를 정확하게 입력해주세요." : ""}
                            fullWidth/>
                        <Button variant="contained" onClick={handleLogin} fullWidth>
                            회원가입
                        </Button>
                        <p>계정이 이미 있나요? <Link href="/signin">로그인</Link></p>
                    </Stack>
                </Card>
            </Box>
        </Container>
    );
}