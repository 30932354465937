// src/utils/toastOptions.ts
import { ToastOptions, Bounce } from "react-toastify";

export const toastOption: ToastOptions = {
    position: "top-right",
    autoClose: 3000,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    theme: "light",
    transition: Bounce,
};