import { jwtDecode } from "jwt-decode";

interface DecodedToken {
    username: string | null;
    permission: string | null;
}

export const decodeToken = (token: string | null): DecodedToken => {
    if (token === null) return { username: null, permission: null };
    return jwtDecode<DecodedToken>(token);
};